import React from "react";
import { Helmet } from "react-helmet";
import Route, { HomeRoute } from "./routes";
import HomePage from "./containers/HomePage";
import PartnersPage from "./containers/PartnersPage";
import ContactPage from "./containers/ContactPage";

import NotFoundPage from "./containers/NotFoundPage";
import { BrowserRouter as Router, Switch } from "react-router-dom";

const App = () => {
	return (
		<Router>
			<React.Fragment>
				<Switch>
					<HomeRoute
						exact
						path="/"
						component={() => {
							return (
								<>
									<Helmet>
										<title>Paradigm Four – Crisis Management Consultant</title>
										<meta
											name="description"
											content="Paradigm Four specializes in external and internal management, leadership, communication and crisis management consulting."
										/>
										<meta
											name="keywords"
											content="crisis-management-consultant, crisis-consulting, crisis-management, crisis-response, crisis-management-professionals, PR-firm, PR, external-crisis-management, internal-crisis-management"
										/>
									</Helmet>
									<HomePage />
								</>
							);
						}}
					/>
					<HomeRoute
						exact
						path="/Clients-Issues"
						component={(p) => {
							return (
								<>
									<Helmet>
										<title>
											Paradigm Four – Crisis Management Professionals
										</title>
									</Helmet>
									<PartnersPage {...p} />
								</>
							);
						}}
					/>
					<HomeRoute
						exact
						path="/Contact"
						component={(p) => {
							return (
								<>
									<Helmet>
										<title>Paradigm Four – Crisis Management Consulting</title>
									</Helmet>
									<ContactPage {...p} />
								</>
							);
						}}
					/>
					<Route component={NotFoundPage} />
				</Switch>
			</React.Fragment>
		</Router>
	);
};

export default App;

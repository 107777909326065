import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import redLine from "../../images/P4-RedStripe.gif";
import redArrow from "../../images/P4-Arrow.png";
import { appendScript, removeScript } from "../../utils/scripts";

const HomePage = () => {
	useEffect(() => {
		appendScript("/assets/js/css3-animate-it.js");

		return () => removeScript("/assets/js/css3-animate-it.js");
	}, []);

	return (
		<div className="">
			<div style={{ display: "flex" }}>
				<img src={redLine} style={{ width: "100%" }} />
			</div>
			<div
				className="black white-text"
				style={{
					background: "#0a0a0a",
					paddingBottom: "30px",
					position: "relative",
				}}>
				<div className="row container center">
					<div className="col s12">
						<h1 style={{ margin: "0 auto", maxWidth: "620px", fontSize: "2.69rem", textAlign: "center" }}>Discreet. Principled. Effective.</h1>
						{/* <p className="content800">
							Specializing in external and internal management, leadership,
							communication, and crisis management consulting.
						</p> */}
						<img src={redArrow} style={{ left: "49%", bottom: "-10px", position: "absolute" }} />
					</div>
				</div>
			</div>
			<div className="clearfix"></div>

			<section className="SB-blue-text">
				<br />

				<div className="row container ">
					<div className="col s12 m12 l12">
						<div className="content800" style={{ textAlign: "justify", maxWidth: "620px" }}>
							<p style={{ fontSize: "26px" }}>
								Specializing in crisis management, leadership <br /> support, external and internal communications, and strategic planning.
							</p>

							<p style={{ fontSize: "26px" }}>
								With the utmost of confidentiality, discretion and integrity, <b>Paradigm Four</b> works with clients to <b>develop and implement strategies that lead to solutions.</b>
							</p>
						</div>
						<div className="clearfix"></div>
					</div>
				</div>

				<br />
			</section>

			<div className="clearfix"></div>
		</div>
	);
};

export default HomePage;

import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import redLine from "../../images/P4-RedStripe.gif";
import redArrow from "../../images/P4-Arrow.png";
import { appendScript, removeScript } from "../../utils/scripts";

const HomePage = () => {
	useEffect(() => {
		appendScript("/assets/js/css3-animate-it.js");

		return () => removeScript("/assets/js/css3-animate-it.js");
	}, []);

	return (
		<div className="">
			{/* <div style={{ display: 'flex' }}>
            <img src={redLine} style={{ width: '100%' }} />
         </div> */}
			<div
				className="black white-text"
				style={{
					background: "#0a0a0a",
					paddingBottom: "110px",
					position: "relative",
				}}>
				<div className="row container center center-align">
					{/* <div className="col s12 m12 l10 offset-l1">
						<h1>
							Higher Education Institutions
							<br />
							<div className="show-on-medium-and-down">
								<br />
							</div>
							Professional Sports Franchises
							<br />
							<div className="show-on-medium-and-down">
								<br />
							</div>
							Corporations
							<br />
							<div className="show-on-medium-and-down">
								<br />
							</div>
							Local Governments
						</h1>
					</div>
					<div className="col s12 m12 l8 offset-l2">
						<p className="content800" style={{ textAlign: "inherit" }}>
							While Paradigm Four does not disclose its client roster, it has
							worked with higher ed institutions, including those at the Power
							Five level in athletics, as well as professional sports
							franchises, athletic conferences, secondary schools, corporations
							and local governments.
						</p>
					</div> */}
					<img src={redArrow} style={{ left: "49%", bottom: "-10px", position: "absolute" }} />
				</div>
			</div>
			<div className="clearfix"></div>

			<section className="SB-blue-text partners">
				<div className="row container ">
					<div className="col s12 m12 l10 offset-l1">
						<div className="" style={{ margin: "0 auto", maxWidth: "765px" }}>
							<h2 className="center-align" style={{ fontSize: "28px", color: "rgb(153,65,70)", textAlign: "left" }}>
								<b>We coach our clients through key aspects of decision-making, helping them navigate any issue.</b>
							</h2>
							<p style={{ fontSize: "22px", textAlign: "left" }}>
								We provide <b>guidance and development</b> to executives and other key stakeholders not only to address today’s challenges but also <b>better prepare</b> them for what lies ahead. Communication to internal and external constituencies is a core aspect of Paradigm
								Four’s work.
							</p>
							<h2 className="center-align" style={{ fontSize: "28px", color: "rgb(153,65,70)", textAlign: "left" }}>
								<b>We are proud of the trust that our clients place in us.</b>
							</h2>
							<p style={{ fontSize: "22px", textAlign: "left" }}>
								Since its inception in 2009, Paradigm Four has worked with more than one hundred universities and colleges, professional sports franchises, corporations and local governments, working directly with Presidents and Chancellors, Boards of Trustees, CEOs, city leaders,
								conference commissioners, Directors of Athletics, and coaches. With backgrounds in journalism, communications, law, and government, Paradigm Four is aptly prepared to efficiently and comprehensively help our clients find the right solutions for them.
							</p>
							<h2 className="center-align" style={{ fontSize: "22px", color: "rgb(153,65,70)", textAlign: "left", marginBottom: "5px" }}>
								<b>Sample areas that Paradigm Four has helped our partners address:</b>
							</h2>
							<div className="row alignAdj" style={{ margin: "0 auto", display: "flex", flexWrap: "wrap", justifyItems: "flex-start", textAlign: "left" }}>
								<div className="col s12 m6 l7" style={{ padding: "0", minWidth: "200px" }}>
									<p style={{ margin: 0, fontSize: "18px" }}>DOJ investigations</p>
									<p style={{ margin: 0, fontSize: "18px" }}>Social Injustices</p>
									<p style={{ margin: 0, fontSize: "18px" }}>DEI Initiatives</p>
									<p style={{ margin: 0, fontSize: "18px" }}>Free Speech and First Amendment</p>
									<p style={{ margin: 0, fontSize: "18px" }}>Corporate Malfeasance</p>
									<p style={{ margin: 0, fontSize: "18px" }}>Title IX and domestic and sexual assault</p>
								</div>
								<div className="col s12 m6 l5" style={{ padding: "0 0 0 0px", minWidth: "200px" }}>
									<p style={{ margin: 0, fontSize: "18px" }}>High-profile Personnel Transitions</p>
									<p style={{ margin: 0, fontSize: "18px" }}>Faculty-Related Concerns</p>
									<p style={{ margin: 0, fontSize: "18px" }}>Employee and Student Misconduct</p>
									<p style={{ margin: 0, fontSize: "18px" }}>Civil and Criminal Legal Matters</p>
									<p style={{ margin: 0, fontSize: "18px" }}>Financial Concerns</p>
									<p style={{ margin: 0, fontSize: "18px" }}>NCAA Enforcement Matters</p>
								</div>
							</div>
							{/* <div className="row alignAdj" style={{ margin: "0" }}>
								<div className="col s12 m6 l6">
									<p>
										<b>Response to social injustices</b>
									</p>
									<p>Free speech and First Amendment issues</p>
								</div>
								<div className="col s12 m6 l6">
									<p>
										<b>Officer involved shootings</b>
									</p>
									<p>NCAA infractions cases</p>
								</div>
							</div>
							<div className="row alignAdj" style={{ margin: "0" }}>
								<div className="col s12 m6 l6">
									<p>
										<b>Racism on campus</b>
									</p>
									<p>Presidential transitions</p>
								</div>
								<div className="col s12 m6 l6">
									<p>
										<b>Sports sponsorship</b>
									</p>
									<p>Apparel partnerships</p>
								</div>
							</div>
							<div className="row alignAdj" style={{ margin: "0" }}>
								<div className="col s12 m6 l6">
									<p>
										<b>Health Care</b>
									</p>
									<p>Title IX issues and domestic and sexual assault</p>
								</div>
								<div className="col s12 m6 l6">
									<p>
										<b>High-profile personnel transitions</b>
									</p>
									<p>Personnel investigations</p>
								</div>
							</div>
							<div className="row alignAdj" style={{ margin: "0" }}>
								<div className="col s12 m6 l6">
									<p>
										<b>Widespread sexual abuse in schools</b>
									</p>
									<p>Response to natural disasters </p>
								</div>
								<div className="col s12 m6 l6">
									<p>
										<b>Name, Image, Likeness and NCAA legislation</b>
									</p>
									<p>Allegations of harassment and bullying</p>
								</div>
							</div>
							<div className="row alignAdj" style={{ margin: "0" }}>
								<div className="col s12 m6 l6">
									<p>
										<b>Financial concerns, budgets and operating deficits</b>
									</p>
									<p>Faculty matters including tenure and governance</p>
								</div>
								<div className="col s12 m6 l6">
									<p>
										<b>Civil litigation</b>
									</p>
									<p>Preparing for Congressional testimony</p>
								</div>
							</div>
							<div className="row alignAdj" style={{ margin: "0" }}>
								<div className="col s12 m6 l6">
									<p>
										<b>Internal governance concerns</b>
									</p>
									<p>Furloughs and layoffs</p>
								</div>
								<div className="col s12 m6 l6">
									<p>
										<b>Team dynamics and communications</b>
									</p>
									<p>Crimes including murder, assault and embezzlement</p>
								</div>
							</div> */}
						</div>
						<div className="clearfix"></div>
					</div>
				</div>
				<br />
				<br />
				<br />
				<br />
			</section>

			<div className="clearfix"></div>
		</div>
	);
};

export default HomePage;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ArrowToTop from "../ScrollTopArrow";
import "../../styles/app.css";
import NavLinks from "./navlinks";
import M from "materialize-css";
import logo from "../../images/P4-Header-Logo.png";
import Hamburger from "react-hamburgers";
import "./hamburgers/hamburgers.scss";
import "../../styles/dropdown.css";

const topStyle = {
	position: "absolute",
	border: "0",
	boxShadow: "0 0 0",
};

const SabreHamberger = () => {
	const [active, setActive] = useState(false);

	return (
		<div className="nav-wrapper">
			<Hamburger
				active={active}
				type="stand"
				onClick={() => setActive(!active)}
			/>
			{active && (
				<>
					<ul className="dropLocation">
						<li className="menu-item">
							<ul className="sub-menu">
								<li className="menu-item sub-menu-item">
									<Link onClick={() => setActive(!active)} to="/Clients-Issues">
										Clients & Issues
									</Link>
								</li>
								<li className="menu-item sub-menu-item">
									<Link onClick={() => setActive(!active)} to="/Contact">
										Contact
									</Link>
								</li>
							</ul>
						</li>
					</ul>
				</>
			)}
		</div>
	);
};

const Home = (props) => {
	useEffect(() => {
		M.Sidenav.init(document.getElementById("mobile-nav"));
	}, []);

	const closeMobileNav = () => {
		let elem = document.getElementById("mobile-nav");
		let instance = M.Sidenav.getInstance(elem);
		instance.close();
	};

	const [state, setState] = React.useState("active");

	const { path } = props;

	return (
		<div className={`${path === "/" ? "headBg" : "black"}`}>
			<div className="container" style={{ minHeight: "105px" }}>
				<div className="nav-wrapper">
					<header
						className="row"
						style={{ position: "relative", zIndex: 100, margin: "0" }}
					>
						<nav className="transparent" style={topStyle}>
							{/* <a
								href="#!"
								data-target="mobile-nav"
								className="sidenav-trigger center"
							>
								<i className="material-icons">menu</i>
							</a> */}
							<SabreHamberger />
							<Link className="" to="/">
								<img src={logo} alt="" className="logoAdj" />
							</Link>
						</nav>
					</header>
					{/* <header className="row" style={{ position: "relative" }}>
						<nav className="transparent" style={topStyle}>
							<ul
								className="right hide-on-med-and-down"
								style={{ margin: "25px 0px 0 0" }}
							>
								<NavLinks closeMobileNav={closeMobileNav} />
							</ul>
						</nav>
					</header> */}
				</div>
			</div>
			<ul className="sidenav uppercaseTxt" id="mobile-nav">
				<li style={{ height: "190px" }}>
					<Link
						className=""
						to="/"
						style={{ border: "0" }}
						onClick={() => closeMobileNav()}
					>
						<img
							src={logo}
							alt=""
							className="responsive-img"
							style={{ marginTop: "15px" }}
						/>
					</Link>
				</li>
				<NavLinks closeMobileNav={closeMobileNav} />
				{/* <li>
					<Link
						className="hvr-underline-reveal"
						to="/Locations"
						onClick={() => closeMobileNav()}
					>
						Why Choose Us
					</Link>
				</li> */}
				{/* <p className="ssIcon center">
               <a href="https://g.page/FoxandWeeks?share" target="_blank" rel="noopener noreferrer" className="fbIcon">
                  <i className="fas fa-map-pin"></i>
               </a>
               <a href="https://www.facebook.com/foxandweekspetsatpeace" target="_blank" rel="noopener noreferrer" className="fbIcon">
                  <i class="fab fa-facebook-f"></i>
               </a>
               <br />
               7200 Hodgson Memorial Drive <br />
               Savannah, GA 31406
               <br />
               912-352-7200
				</p> 
            <p className="ssIcon center">
               <a href="https://g.page/FoxandWeeks?share" target="_blank" rel="noopener noreferrer" className="fbIcon">
                  <i className="fas fa-map-pin"></i>
               </a>
               <a href="https://www.facebook.com/foxandweekspetsatpeace" target="_blank" rel="noopener noreferrer" className="fbIcon">
                  <i class="fab fa-facebook-f"></i>
               </a>
               <br />
               4605 Highway 80E
               <br />
               Savannah, GA 31410
               <br />
               912-898-0900
            </p>
				*/}
			</ul>
			{/* <div
				style={{
					height: "80px",
					backgroundColor: "#F1F1F1",
					position: "absolute",
					top: "439px",
					width: "100%",
					zIndex: "-1",
				}}
			>
				asadasdsd
			</div> */}

			<ArrowToTop />
		</div>
	);
};

export default React.memo(Home);

import React from "react";
import { Link } from "react-router-dom";

function navlinks({ closeMobileNav, ...rest }) {
	console.log({ closeMobileNav });
	return (
		<React.Fragment>
			<li>
				<Link
					className="hvr-underline-reveal"
					to="/About-Us"
					onClick={() => closeMobileNav()}
				>
					About Us
				</Link>
			</li>
			<hr className="fadeEnd" />
			<li>
				<Link
					className="hvr-underline-reveal"
					to="/Why-Choose-Us"
					onClick={() => closeMobileNav()}
				>
					Why Choose Us
				</Link>
			</li>
			<hr className="fadeEnd" />
			<li>
				<Link
					className="hvr-underline-reveal"
					to="/Our-Services"
					onClick={() => closeMobileNav()}
				>
					Our Services
				</Link>
			</li>
			<hr className="fadeEnd" />
			{/* <li>
				<Link
					className="hvr-underline-reveal"
					to="/Our-Caring-Staff"
					onClick={() => closeMobileNav()}
				>
					Our Caring Staff
				</Link>
			</li> */}
			{/* <li>
				<Link
					className="hvr-underline-reveal"
					to="/Our-Locations"
					onClick={() => closeMobileNav()}
				>
					Our Locations
				</Link>
			</li> */}
			<li>
				<Link
					className="hvr-underline-reveal"
					to="/Pet-Tales"
					onClick={() => closeMobileNav()}
				>
					Pet Tales
				</Link>
			</li>
			<hr className="fadeEnd" />
			<li>
				<Link
					className="hvr-underline-reveal"
					to="/FAQs"
					onClick={() => closeMobileNav()}
				>
					FAQs
				</Link>
			</li>
			<hr className="fadeEnd" />
			<li>
				<Link
					className="hvr-underline-reveal"
					to="/Contact-Us"
					onClick={() => closeMobileNav()}
				>
					Contact Us
				</Link>
			</li>
		</React.Fragment>
	);
}

export default navlinks;
